<template>
  <div class="flightsDashboard__bottom">
    <div class="flightsDashboard__subtitle flightsDashboard__subtitle--count">
      <span>Historical flights</span>
      <span class="flightsDashboard__subtitle-count">{{historicalFlights.length}}</span>
    </div>
    <div class="flightsDashboard__history js-flights-history">
      <div class="flightsDashboard__history-header" style="padding-right: 50px;" >
        <div class="flightsDashboard__history-header-inner">
          <div class="flightsDashboard__history-cell">№</div>
          <div class="flightsDashboard__history-cell">Confirm. no</div>
          <div class="flightsDashboard__history-cell">Booking date</div>
          <div class="flightsDashboard__history-cell">Origin</div>
          <div class="flightsDashboard__history-cell">Destination</div>
        </div>
      </div>
      <vue-scroll :ops="ops" class="flightsDashboard__history-body js-flights-history-container">
        <router-link
          v-for="(item, index) of historicalFlights"
          :key="index"
          class="flightsDashboard__history-row js-flights-history-item"
          to="/booking/confirmation"
        >
          <div class="flightsDashboard__history-cell">
            <div class="flightsDashboard__history-index">{{historicalFlights.length - index}}</div>
          </div>
          <div class="flightsDashboard__history-cell">{{item.requestNum}}</div>
          <div class="flightsDashboard__history-cell">{{moment(item.createdOn).format('MM/DD/YYYY')}}</div>
          <div class="flightsDashboard__history-cell">
            <span>{{item.departureAirport}}</span>
            <span class="arrow">
              <SvgIcon name="right-arrow-2"/>
            </span>
          </div>
          <div class="flightsDashboard__history-cell">
            <div class="flightsDashboard__history-cell-mobile">
              <span>{{item.arrivalAirport}}</span>
            <span class="arrow">
              <SvgIcon name="right-arrow-2"/>
            </span>
            </div>
            {{item.arrivalAirport}}
            <!-- <div class="flightsDashboard__history-detail">
              <SvgIcon name="more"/>
            </div> -->
          </div>
        </router-link>
      </vue-scroll>
      <div class="flightsDashboard__history-footer">
        <button class="flightsDashboard__history-button js-flights-history-more" type="button">Show more</button>
        <Spinner/>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import moment from 'moment'

  export default {
    props: ['historicalFlights'],
    name: 'FlightsDashboardHistorical',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    // mounted () {
    //   console.log(this.historicalFlights)
    // },
    methods: {
      moment,
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
