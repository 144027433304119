<template>
  <div
    class="flightsDashboard"
    :class="'flightsDashboard--' + dashboardType"
  >
    <vue-scroll :ops="ops">
      <!-- <div class="flightsDashboard__top">
        <div class="flightsDashboard__header">
          <div class="flightsDashboard__title">Flights - current and upcoming</div>
          <div class="flightsDashboard__nav">
            <div
              class="flightsDashboard__nav-item js-tabNavItem"
              data-id="current"
              @click="tabActivate"
            >
              Current
            </div>
            <div
              class="flightsDashboard__nav-item js-tabNavItem"
              data-id="upcoming"
              @click="tabActivate"
            >
              Upcoming
            </div>
          </div>
        </div>
        <div class="flightsDashboard__content">
          <div class="flightsDashboard__content-item js-tabContentItem">
            <div class="flightsDashboard__content-item-header-title">
              <div class="flightsDashboard__subtitle flightsDashboard__subtitle--count">
                <span>Current flights</span>
                <span class="flightsDashboard__subtitle-count">{{flights.length}}</span>
              </div>
              <div class="flightsDashboard__list-caption">Passengers</div>
            </div>
            <div class="flightsDashboard__content-item-body">
              <div class="flightsDashboard__list">
                <div v-for="item of flights" :key="item.id" class="flightsDashboard__list-item">
                  <div class="flightsDashboard__list-item-left" >
                    <FlightsDashboardCard :flights="item"/>
                  </div>
                  <div class="flightsDashboard__list-item-right" >
                    <div class="flightsDashboard__list-caption-mobile">Passengers</div>
                    <FlightsDashboardPassengers :passengers="item"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flightsDashboard__content-item js-tabContentItem">
            <div class="flightsDashboard__content-item-header-title">
              <div class="flightsDashboard__subtitle flightsDashboard__subtitle--count">
                <span>Upcoming flights</span>
                <span class="flightsDashboard__subtitle-count">2</span>
              </div>
              <div class="flightsDashboard__list-caption">Passengers</div>
            </div>
            <div class="flightsDashboard__content-item-body">
              <div class="flightsDashboard__list">
                <div v-for="item of flights" :key="item.id" class="flightsDashboard__list-item">
                  <div class="flightsDashboard__list-item-left">
                    <FlightsDashboardCard :flights="item"/>
                  </div>
                  <div class="flightsDashboard__list-item-right">
                    <div class="flightsDashboard__list-caption-mobile">Passengers</div>
                    <FlightsDashboardPassengers :passengers="item"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <FlightsDashboardHistorical :historicalFlights="historicalFlights"/>
    </vue-scroll>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  // import FlightsDashboardCard from '@/components/dashboard/FlightsDashboard/FlightsDashboardCard'
  // import FlightsDashboardPassengers from '@/components/dashboard/FlightsDashboard/FlightsDashboardPassengers'
  import FlightsDashboardHistorical from '@/components/dashboard/FlightsDashboard/FlightsDashboardHistorical'
  import './Flights.styl'

  export default {
    props: ['dashboardType'],
    name: 'Flights',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    async created () {
      // await this.$store.dispatch('getCurrentFlights')
      // await this.$store.dispatch('getUpcomingFlights')
      await this.$store.dispatch('getHistoricalFlights')
    },
    computed: {
      currentFlights () {
        return this.$store.state.flights.currentFlights
      },
      upcomingFlights () {
        return this.$store.state.flights.upcomingFlights
      },
      historicalFlightsFlyer () {
        return this.$store.state.flights.historicalFlightsFlyer
      },
      flights () {
        return this.$store.state.flights.flyer
      },
      historicalFlights () {
        return this.$store.state.flights.historicalFlights
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.target)
      },
    },
    components: {
      // FlightsDashboardCard,
      // FlightsDashboardPassengers,
      FlightsDashboardHistorical,
    },
  }
</script>
